<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">学员题库管理(小程序)</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员题库列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="realname"
                type="text"
                size="small"
                clearable
                placeholder="请输入题库名称"
              />
            </div>
            <div title="题库类型" class="searchboxItem ci-full">
              <span class="itemLabel">题库类型:</span>
              <treePopupQuestion
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
              />
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="题库名称"
                align="center"
                prop="questionBankName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="题库类型"
                align="center"
                prop="categoryName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="购买题库人数"
                align="center"
                prop="learnNum"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="操作"
                align="center"
                width="320px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="goInfo(scope.row)"
                    >详情
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="goWrongTopic(scope.row)"
                    >易错题</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import tree from "@/components/treePopup";
import treePopupQuestion from "@/components/treePopupQuestion";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "studentQuestionList",
  components: {
    Empty,
    PageNum,
    // tree,
    treePopupQuestion,  
  },
  mixins: [List],
  data() {
    return {
      // 题库名称
      realname: "",
      // 培训类型 - 回显数据
      ruleForm: {
        Trainingtype: "", // 培训类型
      },
      // 培训类型 - 数据类型
      params: {},
    };
  },
  computed: {},
  created() {},
  methods: {
    // 初始化 - 获取学员题库列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.realname) {
        params.questionBankName = this.realname;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch(
        {
          url: "/miniapp/question/learn/list",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 详情
    goInfo(row) {
      this.$router.push({
        path: '/web/miniApp/studentQuestionListInfo',
        query: row,
      });
    },
    // 易错题
    goWrongTopic(row) {
      this.$router.push({
        path: '/web/miniApp/studentQuestionLisWrongTopic',
        query: row,
      });
    },
    // 培训类型 - 五棵树字段对象
    childBack(params) {
      this.params = { ...params };
    },
    // 培训类型 - 五棵树返现字段
    clearParams() {
      this.params = {};
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>
<style lang="less" scoped></style>
